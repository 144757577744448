import "native-promise-only";
import "bootstrap";
import type {} from "services-comun/modules/types.d.ts";

import {Router} from "../router";
import config from "../config";

require("bootstrap/dist/css/bootstrap.css");

Router.get(config).init().then(async ()=>{}).catch(async (err)=>{
    console.error("Error de router", JSON.stringify(err));
});
