import {Auth} from "../services/auth";
import {IConfig} from "../config";

export interface IRuta {
    id: number;
    ruta: string;
}

export abstract class Ruta {
    /* STATIC */
    /* INSTANCE */
    public get id(): number {
        return this.data.id;
    }

    protected constructor(protected readonly data: IRuta) {

    }

    public abstract check(path: string): Promise<void>;
    public abstract load(auth: Auth, config: IConfig, state?: any): Promise<void>;
    public abstract unload(): Promise<void>;
    public abstract updateEstado(state?: any): Promise<void>;
}
