import {FirebaseApp, initializeApp} from "firebase/app"
import {getAuth, GoogleAuthProvider, onAuthStateChanged, signInWithPopup, Unsubscribe, User, signOut, setPersistence, browserLocalPersistence} from "firebase/auth";
import {error, info} from "services-comun/modules/browser/log";

import {ESeccion, Router} from "../router";
import {Usuario} from "../data/usuario";
import settings from "./settings";
import {AuthPanelFrontendRequest} from "services-comun-ed/modules/services/usuarios/auth/frontend-panel";

export class Auth {
    /* STATIC */
    private static AUTH?: Auth;

    public static async get(router: Router): Promise<Auth> {
        return this.AUTH ??= new this(router);
    }

    /* INSTANCE */
    public fbOK: boolean;
    public fbID: string;
    private readonly firebaseApp: FirebaseApp;
    private readonly unsubscribe: Unsubscribe;
    public usuario?: Usuario;

    private constructor(public router: Router) {
        this.fbOK = false;
        this.fbID = "";
        this.firebaseApp = initializeApp(require("../assets/firebase.json"));
        this.unsubscribe = onAuthStateChanged(getAuth(this.firebaseApp), (user)=>{
            this.onAuthStateChange(user)
                .then(async ()=>{})
                .catch(async (err)=>{
                    console.error("Error durante AuthStateChange", JSON.stringify(err));
                });
        });
    }

    private async onAuthStateChange(user: User|null): Promise<void> {
        //this.unsubscribe();
        if (user != null) {
            info("Ya estamos logueados");
            // todo Estamos logueados => comprobamos con el backend y mostramos la sección actual según la ruta
            this.fbOK = true;
            await this.checkAccessWithBackend();
        } else {
            info("No estamos logueados");
            // todo No estamos logueados => mostramos el botón de login
            this.fbOK = false;
            await this.router.changeSeccion(ESeccion.login, this);
        }
    }

    public async checkAccessWithFirebase(): Promise<void> {
        const auth = getAuth(this.firebaseApp);
        auth.languageCode = 'es';
        const result = await setPersistence(auth, browserLocalPersistence)
            .then(async () => {
                const provider = new GoogleAuthProvider();
                provider.setCustomParameters({
                    prompt: 'select_account'
                });
                return await signInWithPopup(auth, provider);
            })
            .catch(async (error) => {
                await Promise.reject(error.message);
            });
        if (result != null) {
            this.fbOK = true;
            await this.checkAccessWithBackend();
        }
    }

    public async refreshToken(): Promise<void>{
        setInterval(()=>{
            getAuth().currentUser!.getIdToken(true).then((idToken:string) => {
                this.fbID = idToken;
                settings.fbid = idToken;
            });
        }, 5 * 60 * 1000);
    }


    public async checkAccessWithBackend(): Promise<void> {
        const idToken = await getAuth().currentUser!.getIdToken(true);
        const data = await AuthPanelFrontendRequest.login(idToken)
            .catch(async (err)=>{
            error("Error autenticando", err);
            return Promise.reject(err);
            });

        if (data) {
            this.usuario = Usuario.build(data);
            settings.fbid = idToken;
            this.fbID = idToken;
            this.refreshToken();
            await this.router.ejecutar();
        }
    }

    public async signOut() : Promise<void> {
        const auth = getAuth();
        signOut(auth).then(async () => {
            await this.router.changeSeccion(ESeccion.login, this);
        }).catch(async (error) => {
            await Promise.reject(error);
        });
    }

}
