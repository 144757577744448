import {info} from "services-comun/modules/browser/log";

import { Ruta, IRuta } from "../ruta";
import { IConfig } from "../../config";
import {Auth} from "../../services/auth";

export class Login extends Ruta {
    /* STATIC */
    public static build(config: IConfig, id: number): Login {
        return new this({
            id,
            ruta: "",
        });
    }

    /* INSTANCE */
    public constructor(data: IRuta) {
        super(data);
    }

    public async check(path: string): Promise<void> {
    }

    public async load(auth: Auth, config: IConfig): Promise<void> {
        info("Descargando NavBar");
        const {NavBar} = await import(/* webpackChunkName: "navbar" */ `../../componentes/navbar`);
        await NavBar.unload();
        info("Cargando Login");
        const {Login} = await import(/* webpackChunkName: "login" */ `../../componentes/login`);
        await Login.load(auth, config);
    }

    public async unload(): Promise<void> {
        info("Descargando Login");
        const {Login} = await import(/* webpackChunkName: "login" */ `../../componentes/login`);
        await Login.unload();
    }

    public async updateEstado(state?: any): Promise<void> {
        info("Actualizando el estado de NavBar");
        const {NavBar} = await import(/* webpackChunkName: "navbar" */ `../../componentes/navbar`);
        await NavBar.updateEstado(state);
        info("Actualizando el estado de Login");
        const {Login} = await import(/* webpackChunkName: "login" */ `../../componentes/login`);
        await Login.updateEstado(state);
    }
}
