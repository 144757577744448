
import {error} from "services-comun/modules/browser/log";

import {RedactorNoticias} from "./redactor";
import {Auth} from "../services/auth";
import {UsuariosPanelFrontendRequest} from "services-comun-ed/modules/services/usuarios/panel-usuarios/frontend-panel";
import {IRolSimple, IUsuarioCompleto, IUsuarioSimple} from "services-comun-ed/modules/services/usuarios/interface";

export class Usuario implements IUsuarioCompleto {

    private static usuarios: IUsuarioSimple[];

    redactores: RedactorNoticias[]|null;

    /* STATIC */
    public static build(data: IUsuarioCompleto): Usuario {
        return new this(data);
    }

    public static async getUsuarios(auth: Auth): Promise<IUsuarioSimple[]> {
        if(this.usuarios){
            return this.usuarios
        }
        else{
            return await UsuariosPanelFrontendRequest.listar(auth.fbID).catch(async (err)=>{
                error("No se han podido obtener los usuarios.", err);
                return Promise.reject(err);
            });
        }
    }

    /* INSTANCE */
    public get id(): number {
        return this.data.id;
    }
    public get email(): string {
        return this.data.email;
    }
    public get nombre(): string {
        return this.data.nombre;
    }
    public set nombre(nombre: string) {
        this.data.nombre = nombre;
    }
    public get enabled(): boolean {
        return this.data.enabled;
    }
    public get avatar(): string|undefined {
        return this.data.avatar;
    }
    public set avatar(avatar: string|undefined) {
        this.data.avatar = avatar;
    }

    private _registro?: Date;
    public get registro(): string {
        return this.data.registro;
    }
    public get registroDate(): Date {
        return this._registro??=new Date(this.data.registro);
    }
    private _acceso?: Date;
    public get acceso(): string|undefined {
        return this.data.acceso;
    }
    public get roles(): IRolSimple[] {
        return this.data.roles;
    }
    public get permisos(): string[] {
        return this.data.permisos;
    }

    private constructor(private data: IUsuarioCompleto) {
        this.redactores = null;
    }

    toJSON(){
        return this.data;
    }
}
