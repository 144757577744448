import dominio from "services-comun-ed/modules/portal/ed";

export interface IConfig {
    router: {
        basePath: string;
        web: string;
    };
}

const config: IConfig = {
    router: {
        basePath: "",
        web: dominio.get(dominio.REDACCION),
    }
};

export default config;
