import {info} from "services-comun/modules/browser/log";

import { Auth } from "../../../../services/auth";
import { IConfig } from "../../../../config";
import { Ruta, IRuta } from "../../../ruta";

export class RedaccionRedactoresPerfil extends Ruta {
    /* STATIC */
    public static build(config: IConfig, id: number): RedaccionRedactoresPerfil {
        return new this({
            id,
            ruta: `${config.router.basePath}/redaccion/redactores/edit/`,
        });
    }

    /* INSTANCE */
    public constructor(data: IRuta) {
        super(data);
    }

    public async check(path: string): Promise<void> {
        if (!path.startsWith(this.data.ruta)) {
            return Promise.reject("Redaccion/Redactores/Edit: Rechazada");
        }
        let resto = path.substr(this.data.ruta.length).match(/[1-9][\d]*/);
        if (resto==null) {
            return Promise.reject("Redaccion/Redactores/Edit: Rechazada");
        }
    }

    public async load(auth: Auth, config: IConfig, state?: any): Promise<void> {
        info("Cargando NavBar");
        const {NavBar} = await import(/* webpackChunkName: "navbar" */ `../../../../componentes/navbar`);
        await NavBar.load(auth, config, state);
        info("Cargando Redaccion/Redactores/Edit");
        const {RedaccionRedactoresPerfil} = await import(/* webpackChunkName: "redaccion-noticias-edit" */ `../../../../componentes/redaccion/redactores/editar/edit`);
        await RedaccionRedactoresPerfil.load(auth, config, state);
    }

    public async unload(): Promise<void> {
        // info("Descargando NavBar");
        // const {NavBar} = await import(/* webpackChunkName: "navbar" */ `../../../../componentes/navbar`);
        // await NavBar.unload();
        info("Descargando Redaccion/Redactores/Edit");
        const {RedaccionRedactoresPerfil} = await import(/* webpackChunkName: "redaccion-noticias-edit" */ `../../../../componentes/redaccion/redactores/editar/edit`);
        await RedaccionRedactoresPerfil.unload();
    }

    public async updateEstado(state?: any): Promise<void> {
        info("Actualizando el estado de NavBar");
        const {NavBar} = await import(/* webpackChunkName: "navbar" */ `../../../../componentes/navbar`);
        await NavBar.updateEstado(state);
        info("Actualizando el estado de Redaccion/Redactores/Edit");
        const {RedaccionRedactoresPerfil} = await import(/* webpackChunkName: "redaccion-noticias-edit" */ `../../../../componentes/redaccion/redactores/editar/edit`);
        await RedaccionRedactoresPerfil.updateEstado(state);
    }
}
