import cookies from "services-comun/modules/browser/cookies";

const enum TTiempo {
    hora = 3600000,
    dia  = 86400000,
    mes  = 2592000000,
    anno = 31536000000,
}

const enum TTipo {
    firebaseID,
}

class Data {
    private _value?: string;
    public get value(): string|undefined {
        return this._value;
    }
    public set value(value: string|undefined) {
        this._value = value??this.defecto;
        this.save();
    }

    public constructor(private readonly name: string, private readonly defecto: string, private readonly time: TTiempo) {
        this._value = cookies.get(this.name);
    }

    private save():void {
        if (this._value!=undefined) {
            cookies.set(this.name, this._value, new Date(Date.now() + this.time));
        } else {
            this.remove();
        }
    }

    private remove():void {
        cookies.remove(this.name);
    }
}

class Settings {
    private readonly data: Map<TTipo, Data>;

    public get fbid(): string|undefined {
        return this.getFirebaseID().value;
    }
    public set fbid(value: string|undefined) {
        const data =  this.getFirebaseID();
        data.value = value;
    }

    public constructor() {
        this.data = new Map<TTipo, Data>();
    }

    private getFirebaseID(): Data {
        let data = this.data.get(TTipo.firebaseID);
        if (data==undefined) {
            data = new Data(`ed-fbid`, '', TTiempo.mes);
            this.data.set(TTipo.firebaseID, data);
        }
        return data;
    }
}

const settings = new Settings();

export default settings;
