import {info} from "services-comun/modules/browser/log";

import { Auth } from "../../../../services/auth";
import { IConfig } from "../../../../config";
import { Ruta, IRuta } from "../../../ruta";

export class EstadoVideos extends Ruta {
    /* STATIC */
    public static build(config: IConfig, id: number): EstadoVideos {

        return new this({
            id,
            ruta: `${config.router.basePath}/redaccion/videos/estado/`,
        });
    }

    /* INSTANCE */
    public constructor(data: IRuta) {
        super(data);
    }

    public async check(path: string): Promise<void> {
        if (path!=this.data.ruta) {
            return Promise.reject("Redaccion/Videos: Rechazada");
        }
    }

    public async load(auth: Auth, config: IConfig, state?: any): Promise<void> {

        info("Cargando NavBar");
        const {NavBar} = await import(/* webpackChunkName: "navbar" */ `../../../../componentes/navbar`);
        await NavBar.load(auth, config, state);
        info("Cargando Redaccion/Videos");
        const {EstadoVideos} = await import(/* webpackChunkName: "redaccion-noticias" */ `../../../../componentes/redaccion/videos/estado`);
        await EstadoVideos.load(auth, config, state);
    }

    public async unload(): Promise<void> {
        // info("Descargando NavBar");
        // const {NavBar} = await import(/* webpackChunkName: "navbar" */ `../../../componentes/navbar`);
        // await NavBar.unload();
        info("Descargando Redaccion/Videos");
        const {EstadoVideos} = await import(/* webpackChunkName: "redaccion-noticias" */ `../../../../componentes/redaccion/videos/estado`);
        await EstadoVideos.unload();
    }

    public async updateEstado(state?: any): Promise<void> {
        info("Actualizando el estado de NavBar");
        const {NavBar} = await import(/* webpackChunkName: "navbar" */ `../../../../componentes/navbar`);
        await NavBar.updateEstado(state);
        info("Actualizando el estado de Redaccion/Videos");
        const {EstadoVideos} = await import(/* webpackChunkName: "redaccion-noticias" */ `../../../../componentes/redaccion/videos/estado`);
        await EstadoVideos.updateEstado(state);
    }
}
