import {info} from "services-comun/modules/browser/log";

import { Auth } from "../../../services/auth";
import { IConfig } from "../../../config";
import { Ruta, IRuta } from "../../ruta";

export class RedaccionEncuestas extends Ruta {
    /* STATIC */
    public static build(config: IConfig, id: number): RedaccionEncuestas {
        return new this({
            id,
            ruta: `${config.router.basePath}/redaccion/encuestas/`,
        });
    }

    /* INSTANCE */
    public constructor(data: IRuta) {
        super(data);
    }

    public async check(path: string): Promise<void> {
        if (path!=this.data.ruta) {
            return Promise.reject("Redaccion/Encuestas: Rechazada");
        }
    }

    public async load(auth: Auth, config: IConfig, state?: any): Promise<void> {

        info("Cargando NavBar");
        const {NavBar} = await import(/* webpackChunkName: "navbar" */ `../../../componentes/navbar`);
        await NavBar.load(auth, config, state);
        info("Cargando Redaccion/Encuestas");
        const {RedaccionEncuestas} = await import(/* webpackChunkName: "redaccion-noticias" */ `../../../componentes/redaccion/encuestas/encuestas`);
        await RedaccionEncuestas.load(auth, config, state);
    }

    public async unload(): Promise<void> {
        // info("Descargando NavBar");
        // const {NavBar} = await import(/* webpackChunkName: "navbar" */ `../../../componentes/navbar`);
        // await NavBar.unload();
        info("Descargando Redaccion/Noticias");
        const {RedaccionEncuestas} = await import(/* webpackChunkName: "redaccion-noticias" */ `../../../componentes/redaccion/encuestas/encuestas`);
        await RedaccionEncuestas.unload();
    }

    public async updateEstado(state?: any): Promise<void> {
        info("Actualizando el estado de NavBar");
        const {NavBar} = await import(/* webpackChunkName: "navbar" */ `../../../componentes/navbar`);
        await NavBar.updateEstado(state);
        info("Actualizando el estado de Redaccion/Noticias");
        const {RedaccionEncuestas} = await import(/* webpackChunkName: "redaccion-noticias" */ `../../../componentes/redaccion/encuestas/encuestas`);
        await RedaccionEncuestas.updateEstado(state);
    }
}
