import {FrontendRequest} from "services-comun/modules/net/request-frontend";
import {stringify} from "qs";

import {logRejection} from "services-comun/modules/decorators/metodo";
import {IRolCompleto, IUsuarioCompleto, IUsuarioSimple} from "../interface";
import dominio from "../../../portal/ed";

export class UsuariosPanelFrontendRequest extends FrontendRequest {
    /* STATIC */
    private static SERVICIO: string = dominio.get(dominio.SERVICES);

    // @logRejection(true)
    // public static async updateNoticia(auth: string, noticia: INoticiaPanel, publicar: boolean): Promise<any> {
    //     return await this.postJSON<INoticiaPanel, {noticia: INoticiaPanel, publicar: boolean}>(`${this.SERVICIO}/web/backoffice/news/update/`, {noticia, publicar}, {
    //         auth,
    //     }).then(resp => resp.data);
    // }

    @logRejection(true)
    public static async listar(auth: string): Promise<IUsuarioSimple[]> {
        return await this.get<IUsuarioSimple[]>(`${this.SERVICIO}/web/backoffice/usuarios/listar/resumen/`, {
            auth,
        }).then(resp => resp.data);
    }

    @logRejection(true)
    public static async listarPaginado(auth: string, pagina: number, resultados: number): Promise<IUsuarioCompleto[]> {
        return await this.get<IUsuarioCompleto[]>(`${this.SERVICIO}/web/backoffice/usuarios/listar/?pagina=${pagina}&resultados=${resultados}`, {
            auth,
        }).then(resp => resp.data);
    }
    @logRejection(true)
    public static async usuariosActivos(auth: string): Promise<IUsuarioCompleto[]> {
        return await this.get<IUsuarioCompleto[]>(`${this.SERVICIO}/web/backoffice/usuarios/listar/activos/`, {
            auth,
        }).then(resp => resp.data);
    }
    @logRejection(true)
    public static async activar(auth: string, id: number, enable: boolean): Promise<any> {
        return await this.get<IUsuarioSimple[]>(`${this.SERVICIO}/web/backoffice/usuarios/activar/?id=${id}&enable=${enable}`, {
            auth,
        }).then(resp => resp.data);
    }

    @logRejection(true)
    public static async usuario(auth: string, id: number): Promise<IUsuarioCompleto> {
        const params = stringify({id});
        return await this.get<IUsuarioCompleto>(`${this.SERVICIO}/web/backoffice/usuarios/?id=${id}`, {
            auth,
        }).then(resp => resp.data);
    }

    @logRejection(true)
    public static async guardarCambios(auth: string, usuario: IUsuarioCompleto): Promise<any> {
        return await this.postJSON<IUsuarioCompleto, IUsuarioCompleto >(`${this.SERVICIO}/web/backoffice/usuarios/editar/`, usuario, {
            auth,
        }).then(resp => resp.data);
    }

    @logRejection(true)
    public static async listarRoles(auth: string): Promise<IRolCompleto[]> {
        return await this.get<IRolCompleto[]>(`${this.SERVICIO}/web/backoffice/roles/listar/`, {
            auth,
        }).then(resp => resp.data);
    }

    @logRejection(true)
    public static async crearRol(auth: string, nombre: string): Promise<IRolCompleto> {
        return await this.postJSON<IRolCompleto, { nombre: string }>(`${this.SERVICIO}/web/backoffice/roles/add/`, {nombre}, {
            auth,
        }).then(resp => resp.data);
    }

    @logRejection(true)
    public static async eliminarRol(auth: string, rol_id: number): Promise<boolean> {
        return await this.postJSON<boolean,{ rol_id: number }>(`${this.SERVICIO}/web/backoffice/roles/remove/`, {rol_id}, {
            auth,
        }).then(resp => resp.data);
    }

    @logRejection(true)
    public static async listarPermisos(auth: string): Promise<string[]> {
        return await this.get<string[]>(`${this.SERVICIO}/web/backoffice/permisos/listar/`, {
            auth,
        }).then(resp => resp.data);
    }
    @logRejection(true)
    public static async listarPermisosRol(auth: string, rol_id: number): Promise<string[]> {
        return await this.get<string[]>(`${this.SERVICIO}/web/backoffice/permisos/rol/${rol_id}`, {
            auth,
        }).then(resp => resp.data);
    }
    @logRejection(true)
    public static async modPermisosRol(auth: string, rol_id: number, permiso:string, anadir= true): Promise<string[]> {
        return await this.get<string[]>(`${this.SERVICIO}/web/backoffice/roles/${rol_id}/${anadir? 'add':'delete'}/permiso/${permiso}`, {
            auth,
        }).then(resp => resp.data);
    }

    @logRejection(true)
    public static async modUsuariosRol(auth: string, rol_id: number, usuario_id:number, anadir= true): Promise<IUsuarioCompleto> {
        return await this.get<IUsuarioCompleto>(`${this.SERVICIO}/web/backoffice/roles/${rol_id}/${anadir? 'add':'delete'}/usuario/${usuario_id}`, {
            auth,
        }).then(resp => resp.data);
    }
    /* INSTANCE */
}
