import {FrontendRequest} from "services-comun/modules/net/request-frontend";
import {IUsuarioCompleto} from "../interface";
import dominio from "../../../portal/ed";

export class AuthPanelFrontendRequest extends FrontendRequest {
    /* STATIC */
    private static SERVICIO: string = dominio.get(dominio.SERVICES);

    public static async login(auth: string): Promise<IUsuarioCompleto> {
        return await this.get<IUsuarioCompleto>(`${this.SERVICIO}/web/backoffice/login/`, {
            auth,
        }).then(resp => resp.data);
    }

    /* INSTANCE */
}
