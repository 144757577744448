import {info} from "services-comun/modules/browser/log";

import { Auth } from "../../../services/auth";
import { IConfig } from "../../../config";
import { Ruta, IRuta } from "../../ruta";

export class AdminUsuarios extends Ruta {
    /* STATIC */
    public static build(config: IConfig, id: number): AdminUsuarios {
        return new this({
            id,
            ruta: `${config.router.basePath}/admin/usuarios/`,
        });
    }

    /* INSTANCE */
    public constructor(data: IRuta) {
        super(data);
    }

    public async check(path: string): Promise<void> {
        if (path!=this.data.ruta) {
            return Promise.reject("Admin/Usuarios: Rechazada");
        }
    }

    public async load(auth: Auth, config: IConfig, state?: any): Promise<void> {
        require("panel-templates/css/sections/users/list.scss");
        info("Cargando NavBar");
        const {NavBar} = await import(/* webpackChunkName: "navbar" */ `../../../componentes/navbar`);
        await NavBar.load(auth, config, state);
        info("Cargando Admin/Usuarios");
        const {AdminUsuarios} = await import(/* webpackChunkName: "admin-usuarios" */ `../../../componentes/admin/usuarios`);
        await AdminUsuarios.load(auth, config, state);
    }

    public async unload(): Promise<void> {
        // info("Descargando NavBar");
        // const {NavBar} = await import(/* webpackChunkName: "navbar" */ `../../../componentes/navbar`);
        // await NavBar.unload();
        info("Descargando Admin/Usuarios");
        const {AdminUsuarios} = await import(/* webpackChunkName: "admin-usuarios" */ `../../../componentes/admin/usuarios`);
        await AdminUsuarios.unload();
    }

    public async updateEstado(state?: any): Promise<void> {
        info("Actualizando el estado de NavBar");
        const {NavBar} = await import(/* webpackChunkName: "navbar" */ `../../../componentes/navbar`);
        await NavBar.updateEstado(state);
        info("Actualizando el estado de Admin/Usuarios");
        const {AdminUsuarios} = await import(/* webpackChunkName: "admin-usuarios" */ `../../../componentes/admin/usuarios`);
        await AdminUsuarios.updateEstado(state);
    }
}
