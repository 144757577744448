import { info } from "services-comun/modules/browser/log";

import { Home } from "./router/rutas/home";
import { IConfig } from "./config";
import { Ruta } from "./router/ruta";
import { Auth } from "./services/auth";
import { Login } from "./router/rutas/login";
import { AdminPerfil } from "./router/rutas/admin/perfil";
import { AdminUsuarios } from "./router/rutas/admin/usuarios";
import { AdminRoles } from "./router/rutas/admin/roles";
import { RedaccionNoticias } from "./router/rutas/redaccion/noticias";
import { RedaccionNoticiasEdit } from "./router/rutas/redaccion/noticias/edit";
import { RedaccionRedactores } from "./router/rutas/redaccion/redactores";
import { RedaccionRedactoresPerfil } from "./router/rutas/redaccion/redactores/edit";
import {RedaccionTags} from "./router/rutas/redaccion/tags";
import {RedaccionPortadillas} from "./router/rutas/redaccion/portadillas";
import {RedaccionEncuestas} from "./router/rutas/redaccion/encuestas";
import {RedaccionBarraSeo} from "./router/rutas/redaccion/barraSeo";
import {EditarVideos} from "./router/rutas/redaccion/videos/editar";
import {ListarVideos} from "./router/rutas/redaccion/videos/listar";
import {EstadoVideos} from "./router/rutas/redaccion/videos/estado";

export enum ESeccion {
    loading,
    login,
    home,
    admin_perfil,
    admin_usuarios,
    admin_roles,
    redaccion_noticias,
    redaccion_noticias_edit,
    redaccion_redactores,
    redaccion_redactores_perfil,
    redaccion_tags,
    redaccion_portadillas,
    redaccion_encuestas,
    redaccion_barraSeo,
    redaccion_videos_editar,
    redaccion_videos_listar,
    redaccion_videos_estado
}

export class Router {
    /* STATIC */
    private static ROUTER?: Router;

    public static get(config: IConfig): Router {
        return this.ROUTER??=this.build(config);
    }

    private static build(config: IConfig): Router {
        const rutas = [
            Home.build(config, ESeccion.home),
            AdminPerfil.build(config, ESeccion.admin_perfil),
            AdminUsuarios.build(config, ESeccion.admin_usuarios),
            AdminRoles.build(config, ESeccion.admin_roles),
            RedaccionNoticias.build(config, ESeccion.redaccion_noticias),
            RedaccionNoticiasEdit.build(config, ESeccion.redaccion_noticias_edit),
            RedaccionRedactores.build(config, ESeccion.redaccion_redactores),
            RedaccionRedactoresPerfil.build(config, ESeccion.redaccion_redactores_perfil),
            RedaccionTags.build(config, ESeccion.redaccion_tags),
            RedaccionPortadillas.build(config, ESeccion.redaccion_portadillas),
            RedaccionEncuestas.build(config, ESeccion.redaccion_encuestas),
            RedaccionBarraSeo.build(config, ESeccion.redaccion_barraSeo),
            EditarVideos.build(config, ESeccion.redaccion_videos_editar),
            ListarVideos.build(config, ESeccion.redaccion_videos_listar),
            EstadoVideos.build(config, ESeccion.redaccion_videos_estado),
        ];

        return new this(rutas, config);
    }

    /* INSTANCE */
    private readonly login: Login;
    private seccion: ESeccion;
    private secciones: Map<ESeccion, Ruta>;

    private constructor(private readonly rutas: Ruta[], private readonly config: IConfig) {
        this.login = Login.build(config, ESeccion.login);
        this.seccion = ESeccion.loading;
        this.secciones = new Map<ESeccion, Ruta>();

        this.secciones.set(ESeccion.login, this.login);
        for (const actual of rutas) {
            this.secciones.set(actual.id, actual);
        }
    }

    public async init(): Promise<void> {
        await Auth.get(this);
        window.onpopstate = (ev)=>{
            this.ejecutar(ev.state)
                .then(async ()=>{})
                .catch(async ()=>{});
        };
    }

    public async ejecutar(state?: any): Promise<void> {
        const auth = await Auth.get(this);
        if (!auth.fbOK) {
            //todo no hacer nada o cargar el "LOADING" ?
            await this.changeSeccion(ESeccion.login, auth);
        } else {
            const path = window.location.pathname;
            for (const actual of this.rutas) {
                try {
                    await actual.check(path);
                    await this.changeSeccion(actual.id, auth, state);

                    return;
                } catch (e) {

                }
            }
        }
    }

    public async changeSeccion(seccion: ESeccion, auth: Auth, state?: any): Promise<void> {
        if (this.seccion!=seccion) {
            await this.unload();
            await this.load(seccion, auth, state);
        } else if (state!=undefined) {
            await this.updateEstado(state);
        }
    }

    private async load(seccion: ESeccion, auth: Auth, state?: any): Promise<void> {
        info(`Cargando la sección "${ESeccion[seccion]}"`);
        this.seccion = seccion;
        await this.secciones.get(this.seccion)?.load(auth, this.config, state);
    }

    private async unload(): Promise<void> {
        info(`Descargando la sección "${ESeccion[this.seccion]}"`);
        await this.secciones.get(this.seccion)?.unload();
    }

    private async updateEstado(state?: any): Promise<void> {
        info(`Restaurando el estado de la sección "${ESeccion[this.seccion]}"`);
        await this.secciones.get(this.seccion)?.updateEstado(state);
    }
}
